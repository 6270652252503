
<template>
  <div>
    <dialog-loading v-if="dialog_loading"></dialog-loading>
    <page-header title="Formulário de avaliação"></page-header>
    
    <v-col cols="12" sm="10" md="8" lg="6" class="ml-auto mr-auto">
      <card-loading v-if="loading" card_color="white"></card-loading>
      <template v-if="!loading && !course.id && !form.id">
        <v-alert type="error" :value="true">
          Link inválido ou expirado! Entre em contato com o administrador e solicite outro link para avaliação do curso!
        </v-alert>
      </template>
      <template v-if="!loading && course.id && form.id">
        <v-card >
          <v-card-title class="justify-center">
            <div class="text-h4" style="word-break: break-word">{{course.name}}</div>
          </v-card-title>
          <v-card-text>
            <course-details :selected_course="course"></course-details>
          </v-card-text>
        </v-card>
        <div class="pt-10" v-if="!loading">
          <form-content :ghost_rating_form="form" :ghost_rating_form_items="form_items" @sendForm="sendForm"></form-content>
        </div>
      </template>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'GhostRatingIndex',
  components: {
    FormContent: () => import(`@/components/forms/show/FormContent.vue`),
    CourseDetails: () => import(`@/components/courses/CourseDetails.vue`),
  },
  data() {
    return {
      loading: false,
      dialog_loading: false,
      course: {},
      form: {},
      form_items: [],
    }
  },
  methods: {
    setupData(){
      this.loading = true
      if (this.$route.query.tk){
        localStorage.ghost_tk = `Bearer ${this.$route.query.tk}` 
        localStorage.enr_id = this.$route.params.enr_id

        this.getGhostData()
      } else {
        alert('Link quebrado! Entre em contato com o administrador e solicite outro link para avaliação do curso.')
      }
    },
    getGhostData(){
      this.loading = true
      Api.GhostRating.show(this.$route.params.enr_id).then(r=>r.data).then(d=>{
        let {course, form, form_items} = d
        this.course = course
        this.form = form
        this.form_items = form_items
      }).catch(err=>{
        let msg = `ERRO ${err.response.status}`
        this.addNotification({type: 'error', msg})
      }).finally(()=>{
        this.loading = false
      })
    },
    async sendForm(){
      this.dialog_loading = true

      let done_form = this.prepareDoneForm(this.form.id)
      this.createDoneForm(done_form)
    },
    prepareDoneForm(form_id){
      let done_form = {
        form_id: form_id,
        course_id: this.course.id,
        exec_time: 'rating',
        user_id: '',
      }
      let done_personal_info_attributes = this.done_personal_info_attributes
      let done_items_attributes = [
        ...this.done_items_attributes,
      ]
      const formData = new FormData()
      Object.entries(done_form).forEach(([key, value]) => {
        if(value){
          formData.append('done_form['+key+']', value || '');
        }
      });
      Object.entries(done_personal_info_attributes).forEach(([key, value]) => {
          if(value){
            formData.append('done_form[done_personal_info_attributes]['+key+']', value || '');  
          }
      });
      for (let i=0; i< done_items_attributes.length; i++) {
        Object.entries(done_items_attributes[i]).forEach(([key, value]) => {
          if(value){
            formData.append(`done_form[done_items_attributes][${i}][`+key+`]`, value || '');  
          }
        });
      }
      formData.append('done_form[enr_id]', this.$route.params.enr_id);

      return formData
    },
    createDoneForm(done_form){
      Api.GhostRating.create(done_form).then( () => {
        this.addNotification({type: 'success', msg: 'Formulário enviado com sucesso!'})

        this.dialog_loading = false 
        localStorage.removeItem('ghost_tk');
        localStorage.removeItem('enr_id');
        this.$router.push({name: 'GhostRatingConfirmation'})
      }).catch((err) => {
        console.log({err})
        let msg = `ERRO ${err.response.status} - Não foi possível realizar o envio. `
        if (err.response && err.response.data && err.response.data.errors){
          msg += err.response.data.errors
        }
        this.addNotification({type: 'error', msg})
      }).finally(() => { 
        this.dialog_loading = false 
      })
    },
    ...mapActions({
      addNotification: 'Notification/add',
      clearDoneForm: 'DoneForm/clearDoneForm'
    })
  },
  computed: {
    ...mapState({
      user: state=> state.User.user,
      done_personal_info_attributes: state=> state.DoneForm.done_personal_info_attributes,
      done_items_attributes: state=> state.DoneForm.done_items_attributes,
    }),
  },
  beforeDestroy() {
    this.clearDoneForm()
    localStorage.removeItem('ghost_tk');
    localStorage.removeItem('enr_id');
  },
  mounted () {
    this.setupData();
  },
}
</script>

<style scoped>

</style>